.card {
    width: 320px; /* Adjust as needed */
    height: 400px; /* Adjust as needed */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally for the card */
    border-width: 1px;
    border: #666;
  }
  
  .card:hover {
    transform: scale(1.05); /* Slightly zoom in on hover */
  }
  
  .card-cover-container {
    width: 100%;
    height: 240px; /* Adjust based on cover image size */
    display: flex;
    justify-content: center; /* Center the cover image horizontally */
    overflow: hidden;
    padding-top: 10px;
  }
  
  .card-cover {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Maintain aspect ratio, cover container */
  }
  
  .card-content {
    padding: 10px;
    text-align: left; /* Align text to the left */
    width: 100%; /* Ensure content takes up full width */
  }
  
  .card-title {
    font-size: 16px;
    /* font-weight: bold; */
    margin: 0; /* Remove default margin */
    color: #007185;
  }
  
  .card-author {
    font-size: 14px;
    color: #007185;
    margin: 0; /* Remove default margin */
  }
  
  .card-rating {
    font-size: 14px;
    color: #888;
    margin: 0; /* Remove default margin */
  }
  
  .card-edition, .card-price {
    font-size: 14px;
    color: #333;
    margin: 4px 0; /* Add vertical spacing */
  }
  
  .card-description {
    font-size: 12px;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .card-rating-container {
    display: flex;
    align-items: center;
    font-size: 16px; /* Adjust size as needed */
    color: #f5c518; /* Gold color for stars */
  }
  
  .card-rating-container .star {
    margin-right: 2px; /* Space between stars */
  }
  
  .card-rating-container .star.empty {
    color: #ddd; /* Light grey color for empty stars */
  }
  
  @media screen {
    .card {
      width: 250px; /* Adjust as needed */
      height: 350px; /* Adjust as needed */
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: transform 0.3s ease;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center; /* Center horizontally for the card */
      border-width: 1px;
      border: #666;
    }
  }