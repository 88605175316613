.upload-form {
    width: 100%;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .upload-form h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .error-message, .success-message {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .error-message {
    color: red;
  }
  
  .success-message {
    color: green;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  input[type="text"],
  input[type="date"],
  input[type="number"],
  input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  input[type="text"]:focus,
  input[type="date"]:focus,
  input[type="number"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.25);
    outline: none;
  }
  
  input[type="file"] {
    padding: 0;
  }
  
  .submit-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .main-btn:hover {
    background-color: #0056b3;
  }
  
  .main-btn:active {
    transform: scale(0.98);
  }
  .main-btn{
    background-color: #007bff;
    text-align: center;
    cursor: pointer;
  }
  