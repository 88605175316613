/* GenreSlider.css */

.genre-slider-container {
  padding: 16px;
  max-width: 300px; /* Adjust this value as needed */
  height: auto; /* Allows height to adjust based on content */
  min-height: 100vh; /* Ensures it occupies at least the full viewport height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Add vertical scrolling if content exceeds the viewport height */
  margin-top: 20px;
}

.epub-button {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 85%;
}

.epub-button .button-icon {
  margin-right: 8px;
}

.genre-slider-title {
  font-size: 18px;
  margin-bottom: 12px;
}

.genre-list {
  display: flex;
  flex-direction: column;
}

.genre-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.genre-checkbox {
  margin-right: 8px;
}

.genre-label {
  font-size: 16px;
}
/* 
.full-page-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
} */

/* .close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: #fff;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.close-button svg {
  color: #000;
} */
/* GenreSlider.css */

/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px; /* Adjust the width as needed */
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1000; /* Ensure it is on top of other content */
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: #fff;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
}

.sidebar-content {
  padding: 16px;
  overflow-y: auto;
}

.sidebar .genre-slider-title {
  font-size: 18px;
  margin-bottom: 12px;
}

.sidebar .genre-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.sidebar .genre-checkbox {
  margin-right: 8px;
}

/* Hamburger menu styles */
.hamburger-menu {
  display: none;
  cursor: pointer;
}

.hamburger-menu.open .bar {
  background-color: #333;
}

.hamburger-menu .bar {
  display: block;
  width: 25px;
  height: 3px;
  background: #fff;
  margin: 5px 0;
  transition: 0.3s;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1100; /* Ensure it is above the sidebar */
  }
}
