/* Styles for full-page mode */
.full-page-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff; /* Or any other background color */
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.close-button {
    position: absolute;
    top: 80px;
    right: 40px;
    padding: 10px 20px;
    font-size: 16px;
    /* background-color: #000; */
    color: #000000;
    border: none;
    cursor: pointer;
    z-index: 1000;
}

.close-button:hover {
    color: #333;
}

/* Ensuring body overflow is hidden when in full-page mode */
body.full-page {
    overflow: hidden;
}
.container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

/* Container styling */
.card-container {
    padding: 20px;
  }
  
  .header {
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-top: 20px;
    display: flex; /* Use Flexbox to align items in a row */
    align-items: center; /* Vertically center items */
    justify-content: space-between; 
  }
  
  .header-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-right: 15px;
  }
  .language-picker {
    padding: 5px; /* Adjust padding as needed */
    border: 1px solid #ccc; /* Border for dropdown */
    border-radius: 4px; /* Rounded corners */
  }
  .no-items-found {
    text-align: center;
    margin: 20px;
    font-size: 1.2em;
    color: #666;
  }
  .pagination-footer {
    padding-top: 40px; /* Padding for the footer */
  }
  
  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pagination-controls button {
    padding: 10px 15px;
    margin: 0 5px;
    background-color: #2563eb; /* Green background */
    color: white; /* White text */
    border: none; /* Remove borders */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .pagination-controls button:disabled {
    background-color: #ddd; /* Light grey background for disabled buttons */
    cursor: not-allowed; /* Not-allowed cursor for disabled buttons */
  }
  
  .pagination-controls button:hover:not(:disabled) {
    background-color: #2563eb; /* Darker green on hover */
  }
  
  .pagination-controls span {
    margin: 0 10px; /* Spacing around the text */
  }
  
  @media (max-width: 600px) {
    .header-title {
      font-size: 1rem;
      font-weight: 500;
    }
    .header {
      border-bottom: 2px solid #e0e0e0;
      padding-bottom: 10px;
      padding-top: 20px;
    }
    .container {
      flex-wrap: wrap;
      padding: 0 10px;
    }
    .card-container {
      padding: 0;
    }
    .pagination-controls button {
      padding: 8px 12px; /* Smaller padding for mobile */
      font-size: 14px; /* Smaller font size */
    }
  
    .pagination-controls span {
      font-size: 14px; /* Smaller font size for mobile */
    }
  }